import React from 'react';

const Job = React.lazy(() => import('./pages/Job/index'));

const routes = [

    { path: '/', component: Job },

];

export default routes;